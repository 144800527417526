@import "~react-vis/dist/style";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background: #202020;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.box {
  box-shadow: 3px 5px 17px -1px rgba(0, 0, 0, 0.59);
}

.navBg {
  background-color: #202020;
}

.AppBg {
  background-color: #18191b;
  width: 100%;
  min-height: 100vh;
}

.loginBox {
  position: unset;
  width: 100%;
  min-height: 100vh;
}

.faqRowTop.card {
  background-color: #202020;
  border-color: #202020;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 5px -2px;
  border-radius: 20px;
  color: white;
}

.faqRowBot.card {
  background-color: #202020;
  border-color: #202020;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 5px -2px;
  border-radius: 20px;
  color: white;
}

.faqRow.card {
  background-color: #202020;
  border-color: #202020;
  border-radius: 20px;
  color: white;
}

.faqHeader {
  cursor: pointer;
}

.text-purple {
  color: #c062ff;
}

.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkContainer input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  cursor: pointer !important;
  background-color: #eee;
  margin-top: 9px;
}

.spaceTop {
  margin-top: 30px;
}

/* On mouse-over, add a grey background color */
.checkContainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkContainer input:checked ~ .checkmark {
  background-color: #FA923A;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.cardBody {
  background-color: #202020;
  border-color: #202020;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 20px;
  min-width: 20rem !important;
  max-width: 420px !important;
  min-height: 20rem;
  max-height: 420px;
}

.cardBodyAuto {
  background-color: #202020;
  border-color: #202020;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 20px;
  min-width: 20rem !important;
  max-width: 420px !important;
}

.cardBodyEvent {
  background-color: #202020;
  border-color: #202020;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 20px;
  min-width: 20rem;
  max-width: 420px !important;
  min-height: 20rem;
  position: relative;
}

.eventBg {
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 20px;
}

.eventTitle {
  color: white;
  z-index: 1;
  font-weight: 900;
  text-shadow: 1px 3px 9px #000000;
}

.smTitle {
  color: white;
  z-index: 1;
  font-weight: 800;
  text-shadow: 2px 5px 6px #000000;
}

.blurBack {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.eventTitleMedium {
  color: white;
  z-index: 1;
  font-weight: 800;
  text-shadow: 2px 5px 8px #000000;
  text-transform: uppercase;
}

.eventSubtitle {
  color: white;
  z-index: 1;
  font-weight: 300;
  text-shadow: 1px 3px 9px #000000;
}

.eventIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.eventIcon:hover {
  opacity: 0.5;
}

.buttonEvent {
  background-color: #FA923A !important;
  border-radius: 20px !important;
  border: none !important;
  width: auto !important;
}

.mentorBox {
 position: relative;
}

.vignetteMentor {
  position: absolute;
  top: -10px;
  right: -20px;
  z-index: 100;
  width: auto !important;
}


.buttonName {
  background-color: transparent !important;
  border-color: grey !important;
  border-radius: 20px !important;
  border-width: 0.7px !important;
  width: auto !important;
  /* min-width: 100px;
  max-width: 250px; */
}

.buttonNameSelect {
  background-color: #FA923A !important;
  border-color: transparent !important;
  border-radius: 20px !important;
  border-width: 0.7px !important;
  width: auto !important;
  /* min-width: 100px;
  max-width: 250px; */
}

.buttonName:hover {
  background-color: #FA923A !important;
  border-color: transparent !important;
}

.buttonLight {
  background-color: #FA923A !important;
  border-radius: 20px !important;
  border: none !important;
  width: auto !important;
  outline-style: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.buttonLightBig {
  background-color: #FA923A !important;
  border-radius: 40px !important;
  border: none !important;
  width: auto !important;
  padding: 12px !important;
}

.btnHov:hover {
  opacity: 0.7;
  cursor: pointer;
}

.badgeOn {
  color: white;
  background-color: transparent;
  border-color: #FA923A !important;
  border-width: 0.7px !important;
  border: solid;
  padding: 9px !important;
  cursor: pointer;
  min-width: 40px;
}

.badgeOff {
  color: white;
  background-color: transparent;
  border-color: grey !important;
  border-width: 0.7px !important;
  border: solid;
  padding: 9px !important;
  cursor: pointer;
  min-width: 40px;
}

.badgeName {
  color: white;
  background-color: grey;
  border-color: transparent !important;
  border-width: 0.7px !important;
  border: solid;
  padding: 9px !important;
  cursor: pointer;
  /* min-width: 40px; */
}

.badgeName:hover {
  background-color: #FA923A;
}

.price {
  font-size: 80px;
}

.badgeOff:hover {
  border-color: #FA923A !important;
}

.buttonEventRed {
  background-color: #e24c4b !important;
  border-radius: 20px !important;
  border: none !important;
  width: auto !important;
}

.buttonDisable {
  background-color: #858689 !important;
  border-radius: 20px !important;
  border: none !important;
  width: auto !important;
}

.buttonDisableBlue {
  background-color: rgb(184, 145, 10, 0.4) !important;
  border-radius: 20px !important;
  border: none !important;
  width: auto !important;
}

.buttonDisableBlue:hover {
  background-color: #FA923A !important;
}

.eventBottom {
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 80%;
  margin-bottom: 10px;
}

.xpBottom {
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 30px;
  margin-top: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 60%;
  margin-bottom: 10px;
}

.inputSearch {
  color: white !important;
}

.eventTop {
  z-index: 1;
  background-image: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,0.7));
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.inputBg {
  background: transparent !important;
  background-color: transparent !important;
  border: none !important;
  outline-style: none;
  color: white;
  /* border-bottom: 0.2px solid grey !important;
  border-radius: 0px !important; */
}

.button {
  background-color: #FA923A !important;
  border-radius: 20px !important;
  border: none !important;
}

.buttonVign {
  background-color: #ff5b5b !important;
  border-radius: 20px !important;
  border: none !important;
}

.btnSocial {
  background-color: #FA923A !important;
  border-radius: 20px !important;
  border: none !important;
  height: 40px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s;
  -webkit-text-fill-color: white !important;
}

.inputLine {
  border: none;
  border: 0.1px solid white;
  width: 88%;
}

.logoImg {
  position: absolute;
  top: 10px;
  left: 10px;
}

.logoLogin {
  object-fit: cover;
  position: absolute;
  top: 10px;
  left: 10px;
}

.activeColor {
  color: white !important;
}

.stroke.navbar-nav a.menuItem.nav-link {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #aaa;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0 10px;
}

.menuCredit {
  color: #FA923A;
  font-size: 14px;
  margin: 0 10px;
}

.upperTxt {
  font-weight: 800;
  text-transform: uppercase;
}

.smTxt {
  font-weight: 800;
  text-transform: uppercase;
  font-size: 14px;
}

.vignetteTxt {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
}

a.menuItem.nav-link:hover {
  color: white !important;
}

.stroke.navbar-nav a.menuItem.nav-link,
.stroke.navbar-nav a.menuItem.nav-link:after,
.stroke.navbar-nav a.menuItem.nav-link:before {
  transition: all 0.5s;
}

/* stroke */
.stroke.navbar-nav a.menuItem.nav-link {
  position: relative;
}

.stroke.navbar-nav a.menuItem.nav-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: white;
  height: 1px;
}

.stroke.navbar-nav a.menuItem.nav-link:hover:after {
  width: 100%;
}

.eventCover {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.avatar {
  width: 200px;
  height: 200px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%);
}
.xpBg {
  filter: blur(20px);
  width: 98%;
  height: 400px;
  object-fit: cover;
  position: relative;
  opacity: 0.4;
  margin-top: 20px !important;
}

.xpImg {
  /* width: 300px; */
  height: 360px;
  object-fit: cover;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%);
}

.xpDesc {
  object-fit: cover;
  position: absolute;
  top: 70px;
  left: 60%;
  transform: translate(-50%);
}

.profilePic {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.avatarBg {
  filter: blur(20px);
  width: 98%;
  height: 270px;
  object-fit: cover;
  position: relative;
  opacity: 0.4;
  margin-top: 20px !important;
}

.darkCard {
  background-color: #2b2a2a;
  border-color: #2b2a2a;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 20px;
  margin-bottom: 20px;
}

.offerCard {
  background-color: #2b2a2a;
  border-color: #2b2a2a;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px;
  margin-bottom: 20px;
  max-width: 600px !important;
}

.cardImg {
  width: 200px;
  height: 200px;
  cursor: pointer;
  object-fit: cover;
}

.cardImg:hover {
  border: 2px solid #FA923A;
}

.artistCard {
  background-color: transparent;
  border-color: transparent;
  margin-bottom: 20px;
  width: 300px !important;
  height: 300px !important;
}

.cardPack {
  background-color: #2b2a2a !important;
  border-color: #2b2a2a !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  max-width: 400px !important;
  cursor: pointer;
}

.cardPack:hover {
  border-color: #FA923A !important;
  border-width: 2px !important;
}

#cardPack:hover #homeTitle{
  color:  #FA923A !important;
}

.cardPackSelect {
  background-color: #2b2a2a !important;
  border-color: #FA923A !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  max-width: 400px !important;
  min-height: 275px !important;
  min-width: 350px !important;
  /* cursor: pointer; */
}

.fixBot {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}

.cardFailed {
  background-color: #2b2a2a !important;
  border-color: red important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  max-width: 600px !important;
}

.cardPackSelectOne {
  background-color: #2b2a2a !important;
  border-color: #FA923A !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
}

.cardService {
  background-color: #2b2a2a !important;
  border-color: #FA923A !important;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-radius: 10px !important;
  margin-bottom: 20px !important;
  width: 600px;
  position: relative;
}

.cardServiceImg {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  height: 400px;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
  height: 500px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.1))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.cardServiceImgSm {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 16px 0px;
  position: relative;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  object-fit: cover;
  object-position: bottom;
  width: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.1))
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.cardServTxt {
  margin-top: -10px;
}

.profileHeader {
  margin-bottom: 50px;
}

.title {
  text-transform: UPPERCASE;
  font-weight: bold;
  padding-top: 10px;
  padding-left: 10px;
}

.socialIcon {
  width: 60px;
  height: 60px;
  padding: 10px;
  cursor: pointer;
}

.socialIconForm {
  width: 50px;
  height: 50px;
  padding: 10px;
}

.editIcon {
  width: 35px;
  height: 35px;
  padding: 10px;
  cursor: pointer;
}

.editIcon:hover {
  opacity: 0.5;
}

.editBtn {
  top: 15px;
  right: 35px;
  position: absolute;
  border-radius: 20px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border-color: transparent !important;
  cursor: pointer;
}

.closeBtn {
  top: 0px;
  right: 35px;
  position: absolute;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.closeBtnLeft {
  top: 0px;
  left: 35px;
  position: absolute;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.editBtn:hover {
  background-color: rgba(255, 255, 255, 0.6) !important;
}

.addArtist {
  border-radius: 20px !important;
  background-color: #FA923A !important;
  border-color: transparent !important;
  margin-left: 20px !important;
}

.addArtist:hover {
  opacity: 0.8;
}

.addEvt {
  border-radius: 20px !important;
  background-color: #e24c4b !important;
  border-color: transparent !important;
}

.addEvt:hover {
  opacity: 0.8;
}

.editEvtBtn {
  border-radius: 20px !important;
  background-color: #FA923A !important;
  border-color: transparent !important;
  position: absolute;
  top: 10px;
  right: 30px;
}

.topTitle {
  margin-top: -120px;
}

.editEvtBtn:hover {
  background-color: #FA923A55 !important;
}

.modal-content {
  background-color: #202020 !important;
  color: white;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.close {
  color: white !important;
  opacity: 1 !important;
  box-shadow: none !important;
}

.close:focus {
  outline: 0;
}

input.form-control {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 10px;
  width: 100% !important;
}

.dropdownMenuBlue {
  background-color: rgba(33, 33, 33, 1) !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
  color: white;
}

.countryDrop {
  background-color: #373737 !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
  color: white;
  overflow: scroll;
  height: 300px;
  /* min-width: 20rem !important; */
}

.droptext {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-toggle::after {
  float: right !important;
  margin-top: 8px!important;
}

.continentDrop {
  background-color: #373737 !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
  color: white;
  overflow: scroll;
  /* min-width: 12rem !important; */
}

.dropdownMenu {
  background-color: #373737 !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
  color: white;
  overflow: scroll;
}

a.dropdown-item {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
}

a.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  color: white !important;
  font-weight: 800 !important;
}

.selected {
  color: white !important;
  font-weight: 800 !important;
}

button.dropdown-toggle.btn.btn-primary {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 10px;
  width: 100% !important;
}

button.dropdown-toggle.btn.btn-primary:focus {
  box-shadow: none;
  outline: none !important;
}

button.dropdown-toggle.btn.btn-info {
  background-color: #FA923A !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
}

button.dropdown-toggle.btn.btn-info:focus {
  box-shadow: none;
  outline: none !important;
}

div.form-control.col {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px;
  width: 100% !important;
}

input.form-control.socialInput {
  width: 60% !important;
}

textarea {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none !important;
  border-radius: 18px !important;
  padding-left: 20px;
  margin-top: 20px !important;
  min-height: 50px;
  max-height: 300px;
  width: 100% !important;
}

.iconBg {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  padding: 10px;
}

.editPic {
  width: 100px;
  height: 100px;
  cursor: pointer;
  object-fit: cover;
}

.editPic:hover {
  opacity: 0.5;
  cursor: pointer;
}

.modal-content {
  border: none !important;
}

.dateInput {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border: none;
  border-radius: 20px !important;
  padding: 10px;
  margin-top: 20px;
  width: 100% !important;
  color: white;
  cursor: pointer;
  outline: none !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.pillTxt {
  background-color: #e24c4b;
  border-radius: 15px;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
  padding: 5px !important;
}

.pillTxtEdit {
  background-color: #e24c4b;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.badgeBlue {
  background-color: #FA923A;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.pillTxtEditDis {
  background-color: #373737;
  border-radius: 15px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
}

.pillTxtEdit:hover {
  opacity: 0.5;
}

.pillTxtEditDis:hover {
  opacity: 0.5;
}

.vignette {
  width: 70%;
  cursor: pointer;
}

.vignette:hover {
  opacity: 0.5;
}

.videoFormat {
  width: 90%;
  outline: none;
}

.editIconPos {
  position: absolute;
  top: 5px;
  right: 10px;
}

.relPos {
  position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.addIcon {
  width: 20px;
  height: 20px;
}

.evtImgDetail {
  max-width: 300px;
}

.songIcon {
  width: 40px;
  cursor: pointer;
}

.songIcon:hover {
  opacity: 0.7;
}

.login-connectBtn {
  cursor: pointer;
  color: white;
  background-color: #454ffe;
  text-align: center;
  line-height: 53px;
  margin-bottom: 60px;
  margin-top: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 55px;
  width: 250px;
  border-radius: 100px;
  font-size: 20px;
}

.titleBox {
  position: relative;
}

.warningIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 5px;
}

.infoIcon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 18px;
}

.infoIconNext {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.passwordInfo {
  color: #d15751;
  font-size: 12px;
}

.colorTitle {
  color: #FA923A !important;
}

.toggleIcon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.iconShow {
  width: 25px;
  height: 25px;
  top: 10px;
  left: 0px;
  position: absolute;
}

.iconMini {
  width: 15px;
  height: 15px;
}

.deleteArtist {
  width: 15px;
  height: 15px;
  cursor: pointer;
  /* position: absolute;
  right: 0; */
}

.heartIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.policyIcon {
  width: 17px;
  height: 17px;
  cursor: pointer;
}

.modalTitle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -20%);
}

.fullH {
  height: 100%;
}

.fullScreen {
  height: 80vh;
}

.subs {
  font-size: 13px;
  text-align: left;
}

.cross {
  position: relative;
  display: inline-block;
}
.cross::before,
.cross::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.cross::before {
  border-bottom: 4px solid red;
  -webkit-transform: skewY(-20deg);
  transform: skewY(-20deg);
}
/* .cross::after {
  border-bottom: 2px solid #454ffe;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
} */

.hideT {
  opacity: 0;
}

.react-datepicker__input-time-container
  .react-datepicker-time__input-container
  .react-datepicker-time__input
  input {
  width: 100% !important;
}

.inputSearch {
  color: white !important;
}

.wrapBtn {
  white-space: normal !important;
  word-wrap: break-word;
}


#badgePill .unselectPill {
  background-color: transparent !important;
  border-width: 1px;
  border-style: solid;
  border-color: #FA923A;
  cursor: pointer;
  font-size: 16px;
}

#badgePill .unselectPill:hover {
  background-color: #FA923A !important;
  cursor: pointer;
}

#badgePill .selectPill {
  background-color: #FA923A !important;
  border-width: 1px;
  border-style: solid;
  border-color: #FA923A;
  cursor: pointer;
  font-size: 16px;
}

.dropSelect {
  color: #FA923A !important;
  font-weight: 800 !important;
}

.goldT {
  color: #FA923A !important;
}

#modalMember .modal-footer {
  justify-content: flex-start !important;
}

#modalMember .modal-body {
  padding: 2rem !important;
}

#modalMember .form-control  {
  padding: 0.5rem 1rem !important;
}

#modalMember .form-label   {
  margin-bottom: 2px !important;
  font-size: 12px !important;
}

.motivationInput {
  margin-top: 12px !important;
  height: 85% !important;
}


.reserved {
  color: white;
  font-size: 12px;
  left:0;
  right:0;

  margin-left: auto;
  margin-right: auto;

  position: absolute;
}

.pointer {
  cursor: pointer !important;
  text-decoration: underline;
}

.zindexBtn {
  z-index: 100 !important;
}

.paraSpace {
  padding-bottom: 20px !important;
}